<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <CreditNoteRefundsAutocomplete
          @passCustomerSelect="customer = $event"
          @passCreditNotes="unfiltered_credit_notes = $event"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <CreditNoteRefundsForm
          ref="CreditNoteRefundsForm"
          v-if="customer"
          :customer="customer.customer_name"
          :unfiltered_credit_notes="unfiltered_credit_notes"
          :transaction="transaction"
          @passBack="$emit('passBack')"
          @passModalClose="$emit('passModalClose', $event)"
          @changeDisabled="disabled = $event"
        />
        <v-alert text type="info" v-else>Select a customer</v-alert>
      </v-col>
    </v-row>
    <!--Buttons-->
    <!--Divider-->
    <v-row class="my-3">
      <v-col>
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mb-1">
        <AllocationBackBtn @passBack="$emit('passBack', $event)" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// Go Back step
// Select credit note // notes
export default {
  name: "CreditNoteRefundsWrapper",
  props: ["transaction"],
  components: {
    CreditNoteRefundsAutocomplete: () =>
      import("./CreditNoteRefundsAutocomplete.vue"),
    CreditNoteRefundsForm: () => import("./CreditNoteRefundsForm.vue"),
    AllocationBackBtn: () => import("../AllocationBackBtn.vue"),
  },
  data() {
    return {
      customer: null,
      unfiltered_credit_notes: [],
      disabled: false,
      loading: false,
    };
  },
  watch: {
    // Reset selected credit notes if customer changes
    customer(newValue, oldValue) {
      if (!oldValue || newValue === oldValue) return;
      this.$refs.CreditNoteRefundsForm.resetSelected();
    },
  },
  methods: {
    test() {
      alert("Working");
    },
  },
};
</script>